import { cva } from 'class-variance-authority'
import { useState } from 'react'
import { cn } from '#utils/utils.js'
import { Icon, type IconName } from './Icon'

export const inputStyles = cva(
  'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/70 focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-muted focus-visible:border-primary transition-colors duration-300'
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: IconName
}

const Input = ({ className, icon, type = 'text', ...props }: InputProps) => {
  return (
    <div className="relative w-auto h-full" data-slot="input-wrapper">
      <input
        type={type}
        className={cn(
          inputStyles(),
          ' read-only:select-none read-only:pointer-events-none read-only:opacity-50 read-only:bg-muted read-only:cursor-not-allowed',
          icon && 'peer pl-9',
          className
        )}
        {...props}
      />
      {icon ? (
        <div className="absolute left-0 top-0 flex h-full w-10 items-center justify-center text-muted-foreground/50 peer-focus:text-brand">
          <Icon name={icon} size="sm" />
        </div>
      ) : null}
    </div>
  )
}

const CurrencyInput = ({
  defaultValue: defaultValueProp,
  onValueChange,
  icon,
  ...props
}: React.ComponentProps<typeof Input> & {
  onValueChange?: (value: string) => void
}) => {
  const defaultValue = defaultValueProp ? String(defaultValueProp) : ''

  const [value, setValue] = useState<string>(formatWithCommas(defaultValue))

  const [numberValue, setNumberValue] = useState<number>()

  function formatWithCommas(input: string) {
    const parts = input.split('.')

    // Format integer part with commas if it exists
    if (parts[0]) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return parts.length > 1 ? parts.join('.') : (parts[0] as string)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value

    // Remove any non-numeric, non-period characters
    input = input.replace(/[^0-9.]/g, '')

    const parts = input.split('.')

    if (parts.length > 2) {
      // If there are multiple periods, keep only the first one and truncate the rest
      input = `${parts[0]}.${parts[1]!.slice(0, 2)}`
    } else if (parts.length === 2 && parts[1]!.length > 2) {
      // If there's one period, truncate the decimal to two digits
      input = `${parts[0]}.${parts[1]!.slice(0, 2)}`
    }

    // Update state with the formatted value
    const formattedValue = formatWithCommas(input)
    setValue(formattedValue)
    setNumberValue(Number.parseFloat(input))
    onValueChange?.(input)
  }

  return (
    <>
      <input {...props} type="hidden" value={numberValue ?? ''} />
      <Input
        icon={icon}
        placeholder="Enter amount"
        name={`${props.name}_formatted_currency`}
        type="text"
        inputMode="numeric"
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

export { Input, CurrencyInput }
